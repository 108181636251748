import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "รายละเอียดของคอร์ส",
      "style": {
        "position": "relative"
      }
    }}>{`รายละเอียดของคอร์ส`}<a parentName="h1" {...{
        "href": "#%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%A5%E0%B8%B0%E0%B9%80%E0%B8%AD%E0%B8%B5%E0%B8%A2%E0%B8%94%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA",
        "aria-label": "รายละเอียดของคอร์ส permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
 ภาษา C เบื้องต้น
    </LeadParagraph>
    <h2 {...{
      "id": "เกี่ยวกับคอร์สเรียน",
      "style": {
        "position": "relative"
      }
    }}>{`เกี่ยวกับคอร์สเรียน`}<a parentName="h2" {...{
        "href": "#%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99",
        "aria-label": "เกี่ยวกับคอร์สเรียน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`คอร์สเรียนนี้ออกแบบมาเพื่อให้นักเรียนมัธยมมีความเข้าใจพื้นฐานเกี่ยวกับภาษา C ซึ่งเป็นภาษาโปรแกรมที่ได้รับความนิยมและใช้งานอย่างกว้างขวาง คอร์สเรียนนี้จะครอบคลุมหัวข้อต่างๆ ตั้งแต่พื้นฐานการเขียนโปรแกรมไปจนถึงแนวคิดขั้นสูงที่เพียงพอต่อการนำไปใช้งานและใช้เรียน`}</p>
    <h3 {...{
      "id": "คอร์สนี้เหมาะกับใคร",
      "style": {
        "position": "relative"
      }
    }}>{`คอร์สนี้เหมาะกับใคร`}<a parentName="h3" {...{
        "href": "#%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B9%80%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%B0%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%83%E0%B8%84%E0%B8%A3",
        "aria-label": "คอร์สนี้เหมาะกับใคร permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`บุคคลทั่วไปที่ต้องการเรียนเสริมความรู้ด้านการเขียนโปรแกรม`}</li>
      <li parentName="ul">{`น้องๆนักเรียน นักศึกษา ครูหรืออาจารย์ เรียนเพื่อนำความรู้ไปต่อยอดในชั้นเรียน`}</li>
    </ul>
    <h2 {...{
      "id": "เนื้อหาเกี่ยวกับคอร์สนี้",
      "style": {
        "position": "relative"
      }
    }}>{`เนื้อหาเกี่ยวกับคอร์สนี้`}<a parentName="h2" {...{
        "href": "#%E0%B9%80%E0%B8%99%E0%B8%B7%E0%B9%89%E0%B8%AD%E0%B8%AB%E0%B8%B2%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA%E0%B8%99%E0%B8%B5%E0%B9%89",
        "aria-label": "เนื้อหาเกี่ยวกับคอร์สนี้ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "บทที่-1-การเริ่มต้นใช้งานภาษา-c",
      "style": {
        "position": "relative"
      }
    }}>{`บทที่ 1: การเริ่มต้นใช้งานภาษา C`}<a parentName="h3" {...{
        "href": "#%E0%B8%9A%E0%B8%97%E0%B8%97%E0%B8%B5%E0%B9%88-1-%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%A0%E0%B8%B2%E0%B8%A9%E0%B8%B2-c",
        "aria-label": "บทที่ 1 การเริ่มต้นใช้งานภาษา c permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`ประวัติความเป็นมาของภาษา C`}</li>
      <li parentName="ul">{`การติดตั้งและการตั้งค่าสภาพแวดล้อมการพัฒนา (IDE)`}</li>
      <li parentName="ul">{`โครงสร้างของโปรแกรม C อย่างง่าย`}</li>
      <li parentName="ul">{`การพิมพ์ข้อความออกทางหน้าจอ (printf)`}</li>
      <li parentName="ul">{`ตัวแปรและประเภทข้อมูลพื้นฐาน (int, float, char)`}</li>
      <li parentName="ul">{`คำถามท้ายบทที่ 1`}</li>
    </ul>
    <h3 {...{
      "id": "บทที่-2-ตัวดำเนินการและนิพจน์",
      "style": {
        "position": "relative"
      }
    }}>{`บทที่ 2: ตัวดำเนินการและนิพจน์`}<a parentName="h3" {...{
        "href": "#%E0%B8%9A%E0%B8%97%E0%B8%97%E0%B8%B5%E0%B9%88-2-%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B8%94%E0%B8%B3%E0%B9%80%E0%B8%99%E0%B8%B4%E0%B8%99%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%99%E0%B8%B4%E0%B8%9E%E0%B8%88%E0%B8%99%E0%B9%8C",
        "aria-label": "บทที่ 2 ตัวดำเนินการและนิพจน์ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`ตัวดำเนินการทางคณิตศาสตร์ (+, -, *, /)`}</li>
      <li parentName="ul">{`ตัวดำเนินการเปรียบเทียบ (`}{`=`}{`=`}{`, `}{`!`}{`=`}{`, `}{`>`}{`, `}{`<`}{`, `}{`>`}{`=`}{`, `}{`<`}{`=`}{`)`}</li>
      <li parentName="ul">{`ตัวดำเนินการตรรกะ (&&, ||, !)`}</li>
      <li parentName="ul">{`ลำดับความสำคัญของตัวดำเนินการ`}</li>
      <li parentName="ul">{`เพิ่มการสอนตัวดำเนินการระดับบิต (&,|,^,~,`}{`<`}{`<`}{`,`}{`>`}{`>`}{`)`}</li>
      <li parentName="ul">{`เพิ่มการสอนตัวดำเนินการกำหนดค่าแบบผสม(+=,-=,*=,/=,%=)`}</li>
      <li parentName="ul">{`คำถามท้ายบทที่ 2`}</li>
    </ul>
    <h3 {...{
      "id": "บทที่-3-คำสั่งควบคุมการทำงาน",
      "style": {
        "position": "relative"
      }
    }}>{`บทที่ 3: คำสั่งควบคุมการทำงาน`}<a parentName="h3" {...{
        "href": "#%E0%B8%9A%E0%B8%97%E0%B8%97%E0%B8%B5%E0%B9%88-3-%E0%B8%84%E0%B8%B3%E0%B8%AA%E0%B8%B1%E0%B9%88%E0%B8%87%E0%B8%84%E0%B8%A7%E0%B8%9A%E0%B8%84%E0%B8%B8%E0%B8%A1%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%97%E0%B8%B3%E0%B8%87%E0%B8%B2%E0%B8%99",
        "aria-label": "บทที่ 3 คำสั่งควบคุมการทำงาน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`คำสั่ง if, else, else if: อธิบายการใช้งานคำสั่ง if ในการสร้างเงื่อนไขในการทำงานของโปรแกรม`}</li>
      <li parentName="ul">{`คำสั่ง switch: อธิบายการใช้งานคำสั่ง switch ในการเลือกทำงานตามเงื่อนไขที่หลากหลาย`}</li>
      <li parentName="ul">{`คำสั่ง for loop: อธิบายการใช้งานคำสั่ง for ในการวนลูปการทำงานของโปรแกรม`}</li>
      <li parentName="ul">{`คำสั่ง while loop: อธิบายการใช้งานคำสั่ง while ในการวนลูปการทำงานของโปรแกรมตามเงื่อนไข`}</li>
      <li parentName="ul">{`คำสั่ง do-while loop: อธิบายการใช้งานคำสั่ง do while ในการวนลูปการทำงานของโปรแกรมโดยตรวจสอบเงื่อนไขตอนท้าย`}</li>
      <li parentName="ul">{`คำสั่ง break และ continue: อธิบายการใช้งานคำสั่ง break และ continue ในการควบคุมการทำงานของลูป`}</li>
      <li parentName="ul">{`คำถามท้ายบทที่ 3`}</li>
    </ul>
    <h3 {...{
      "id": "บทที่-4-ฟังก์ชัน",
      "style": {
        "position": "relative"
      }
    }}>{`บทที่ 4: ฟังก์ชัน`}<a parentName="h3" {...{
        "href": "#%E0%B8%9A%E0%B8%97%E0%B8%97%E0%B8%B5%E0%B9%88-4-%E0%B8%9F%E0%B8%B1%E0%B8%87%E0%B8%81%E0%B9%8C%E0%B8%8A%E0%B8%B1%E0%B8%99",
        "aria-label": "บทที่ 4 ฟังก์ชัน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`การสร้างและเรียกใช้ฟังก์ชัน: อธิบายวิธีการสร้างฟังก์ชันเพื่อแบ่งแยกการทำงานของโปรแกรมเป็นส่วนๆ และการเรียกใช้ฟังก์ชันเหล่านั้น`}</li>
      <li parentName="ul">{`การส่งผ่านอาร์กิวเมนต์ไปยังฟังก์ชัน: อธิบายวิธีการส่งค่าไปยังฟังก์ชันเพื่อใช้ในการประมวลผล`}</li>
      <li parentName="ul">{`ค่าที่ฟังก์ชันส่งกลับ: อธิบายวิธีการให้ฟังก์ชันส่งค่ากลับไปยังส่วนที่เรียกใช้`}</li>
      <li parentName="ul">{`ฟังก์ชันมาตรฐานของภาษา C: แนะนำฟังก์ชันมาตรฐานต่างๆ ที่มีอยู่ในไลบรารีของภาษา C`}</li>
      <li parentName="ul">{`คำถามท้ายบทที่ 4`}</li>
    </ul>
    <h3 {...{
      "id": "บทที่-5-อาร์เรย์",
      "style": {
        "position": "relative"
      }
    }}>{`บทที่ 5: อาร์เรย์`}<a parentName="h3" {...{
        "href": "#%E0%B8%9A%E0%B8%97%E0%B8%97%E0%B8%B5%E0%B9%88-5-%E0%B8%AD%E0%B8%B2%E0%B8%A3%E0%B9%8C%E0%B9%80%E0%B8%A3%E0%B8%A2%E0%B9%8C",
        "aria-label": "บทที่ 5 อาร์เรย์ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`การประกาศและใช้งานอาร์เรย์: อธิบายวิธีการประกาศอาร์เรย์เพื่อเก็บข้อมูลหลายค่าในตัวแปรเดียว และวิธีการเข้าถึงข้อมูลในอาร์เรย์`}</li>
      <li parentName="ul">{`อาร์เรย์หลายมิติ: อธิบายวิธีการสร้างและใช้งานอาร์เรย์ที่มีมากกว่าหนึ่งมิติ เช่น อาร์เรย์สองมิติสำหรับเก็บข้อมูลในรูปแบบตาราง`}</li>
      <li parentName="ul">{`การส่งผ่านอาร์เรย์ไปยังฟังก์ชัน: อธิบายวิธีการส่งอาร์เรย์เป็นอาร์กิวเมนต์ไปยังฟังก์ชัน`}</li>
      <li parentName="ul">{`การเรียงลำดับและค้นหาข้อมูลในอาร์เรย์: อธิบายอัลกอริทึมพื้นฐานสำหรับการเรียงลำดับและค้นหาข้อมูลในอาร์เรย์`}</li>
      <li parentName="ul">{`คำถามท้ายบทที่ 5`}</li>
    </ul>
    <h3 {...{
      "id": "บทที่-6-ตัวชี้-pointers",
      "style": {
        "position": "relative"
      }
    }}>{`บทที่ 6: ตัวชี้ (Pointers)`}<a parentName="h3" {...{
        "href": "#%E0%B8%9A%E0%B8%97%E0%B8%97%E0%B8%B5%E0%B9%88-6-%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B8%8A%E0%B8%B5%E0%B9%89-pointers",
        "aria-label": "บทที่ 6 ตัวชี้ pointers permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`แนวคิดของตัวชี้: อธิบายแนวคิดพื้นฐานของตัวชี้ ซึ่งเป็นตัวแปรที่เก็บตำแหน่งหน่วยความจำของตัวแปรอื่น`}</li>
      <li parentName="ul">{`การประกาศและใช้งานตัวชี้: อธิบายวิธีการประกาศตัวชี้และวิธีการเข้าถึงค่าที่ตัวชี้ชี้ไป`}</li>
      <li parentName="ul">{`การส่งผ่านตัวชี้ไปยังฟังก์ชัน: อธิบายวิธีการส่งตัวชี้เป็นอาร์กิวเมนต์ไปยังฟังก์ชัน เพื่อแก้ไขค่าของตัวแปรภายนอกฟังก์ชัน`}</li>
      <li parentName="ul">{`การใช้งานตัวชี้กับอาร์เรย์: อธิบายความสัมพันธ์ระหว่างตัวชี้และอาร์เรย์ และวิธีการใช้ตัวชี้ในการเข้าถึงข้อมูลในอาร์เรย์`}</li>
      <li parentName="ul">{`คำถามท้ายบทที่ 6`}</li>
    </ul>
    <h3 {...{
      "id": "บทที่-7-สตริง-strings",
      "style": {
        "position": "relative"
      }
    }}>{`บทที่ 7: สตริง (Strings)`}<a parentName="h3" {...{
        "href": "#%E0%B8%9A%E0%B8%97%E0%B8%97%E0%B8%B5%E0%B9%88-7-%E0%B8%AA%E0%B8%95%E0%B8%A3%E0%B8%B4%E0%B8%87-strings",
        "aria-label": "บทที่ 7 สตริง strings permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`การประกาศและใช้งานสตริง: อธิบายวิธีการประกาศและใช้งานสตริงในภาษา C ซึ่งเป็นอาร์เรย์ของตัวอักษรที่สิ้นสุดด้วย null character ('\\0')`}</li>
      <li parentName="ul">{`ฟังก์ชันจัดการสตริงมาตรฐาน: แนะนำฟังก์ชันมาตรฐานต่างๆ ที่มีอยู่ในไลบรารี string.h สำหรับจัดการสตริง เช่น strlen(), strcpy(), strcat(), และ strcmp()`}</li>
      <li parentName="ul">{`การแปลงสตริงเป็นตัวเลขและตัวเลขเป็นสตริง: อธิบายวิธีการแปลงสตริงเป็นตัวเลข (เช่น atoi(), atof()) และตัวเลขเป็นสตริง (เช่น sprintf())`}</li>
      <li parentName="ul">{`คำถามท้ายบทที่ 7`}</li>
    </ul>
    <h3 {...{
      "id": "บทที่-8-โครงสร้างข้อมูล-structures",
      "style": {
        "position": "relative"
      }
    }}>{`บทที่ 8: โครงสร้างข้อมูล (Structures)`}<a parentName="h3" {...{
        "href": "#%E0%B8%9A%E0%B8%97%E0%B8%97%E0%B8%B5%E0%B9%88-8-%E0%B9%82%E0%B8%84%E0%B8%A3%E0%B8%87%E0%B8%AA%E0%B8%A3%E0%B9%89%E0%B8%B2%E0%B8%87%E0%B8%82%E0%B9%89%E0%B8%AD%E0%B8%A1%E0%B8%B9%E0%B8%A5-structures",
        "aria-label": "บทที่ 8 โครงสร้างข้อมูล structures permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`การสร้างและใช้งานโครงสร้างข้อมูล: อธิบายวิธีการสร้างโครงสร้างข้อมูลเพื่อเก็บข้อมูลหลายประเภทในตัวแปรเดียว`}</li>
      <li parentName="ul">{`การใช้งานโครงสร้างข้อมูลกับตัวชี้: อธิบายวิธีการใช้ตัวชี้เพื่อเข้าถึงข้อมูลในโครงสร้างข้อมูล`}</li>
      <li parentName="ul">{`เพิ่มการสอน Union และ Enum`}</li>
      <li parentName="ul">{`คำถามท้ายบทที่ 8`}</li>
    </ul>
    <h3 {...{
      "id": "บทที่-9-ไฟล์และการจัดการไฟล์",
      "style": {
        "position": "relative"
      }
    }}>{`บทที่ 9: ไฟล์และการจัดการไฟล์`}<a parentName="h3" {...{
        "href": "#%E0%B8%9A%E0%B8%97%E0%B8%97%E0%B8%B5%E0%B9%88-9-%E0%B9%84%E0%B8%9F%E0%B8%A5%E0%B9%8C%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%88%E0%B8%B1%E0%B8%94%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%84%E0%B8%9F%E0%B8%A5%E0%B9%8C",
        "aria-label": "บทที่ 9 ไฟล์และการจัดการไฟล์ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`การเปิดและปิดไฟล์: อธิบายวิธีการเปิดไฟล์เพื่ออ่านหรือเขียนข้อมูล และวิธีการปิดไฟล์เมื่อใช้งานเสร็จ`}</li>
      <li parentName="ul">{`การอ่านและเขียนข้อมูลจากไฟล์: อธิบายวิธีการอ่านข้อมูลจากไฟล์และเขียนข้อมูลลงในไฟล์ โดยใช้ฟังก์ชันต่างๆ เช่น fprintf(), fscanf(), fread(), และ fwrite()`}</li>
      <li parentName="ul">{`การจัดการไฟล์ข้อความและไฟล์ไบนารี: อธิบายความแตกต่างระหว่างไฟล์ข้อความและไฟล์ไบนารี และวิธีการจัดการไฟล์แต่ละประเภท`}</li>
      <li parentName="ul">{`คำถามท้ายบทที่ 9`}</li>
    </ul>
    <h3 {...{
      "id": "บทที่-10-การจัดสรรหน่วยความจำแบบไดนามิก",
      "style": {
        "position": "relative"
      }
    }}>{`บทที่ 10: การจัดสรรหน่วยความจำแบบไดนามิก`}<a parentName="h3" {...{
        "href": "#%E0%B8%9A%E0%B8%97%E0%B8%97%E0%B8%B5%E0%B9%88-10-%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%88%E0%B8%B1%E0%B8%94%E0%B8%AA%E0%B8%A3%E0%B8%A3%E0%B8%AB%E0%B8%99%E0%B9%88%E0%B8%A7%E0%B8%A2%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%88%E0%B8%B3%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B9%84%E0%B8%94%E0%B8%99%E0%B8%B2%E0%B8%A1%E0%B8%B4%E0%B8%81",
        "aria-label": "บทที่ 10 การจัดสรรหน่วยความจำแบบไดนามิก permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`ฟังก์ชัน malloc, calloc, realloc, free: อธิบายวิธีการใช้ฟังก์ชันเหล่านี้ในการจองหน่วยความจำแบบไดนามิก การปรับขนาดหน่วยความจำ และการคืนหน่วยความจำเมื่อไม่ใช้งานแล้ว`}</li>
      <li parentName="ul">{`การใช้งานหน่วยความจำแบบไดนามิกกับโครงสร้างข้อมูล: อธิบายวิธีการใช้หน่วยความจำแบบไดนามิกในการสร้างโครงสร้างข้อมูลที่ซับซ้อน เช่น ลิงก์ลิสต์และต้นไม้`}</li>
      <li parentName="ul">{`การป้องกันหน่วยความจำรั่ว (memory leaks): อธิบายวิธีการป้องกันการเกิดหน่วยความจำรั่ว ซึ่งเป็นปัญหาที่เกิดขึ้นเมื่อโปรแกรมไม่คืนหน่วยความจำที่จองไว้`}</li>
      <li parentName="ul">{`คำถามท้ายบทที่ 10`}</li>
    </ul>
    <h3 {...{
      "id": "จำนวน-20-ชั่วโมง",
      "style": {
        "position": "relative"
      }
    }}>{`จำนวน 20 ชั่วโมง`}<a parentName="h3" {...{
        "href": "#%E0%B8%88%E0%B8%B3%E0%B8%99%E0%B8%A7%E0%B8%99-20-%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87",
        "aria-label": "จำนวน 20 ชั่วโมง permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`ค่าใช้จ่าย 3500 บาท สำหรับ 3 คนแรกลดให้ 50 % เหลือเพียง 1750 บาทเท่านั้น`}</li>
      <li parentName="ul">{`ระยะเวลาเรียน 10 วัน วันละ 2 ชั่วโมง เริ่มเรียน วันที่ 17/4/68-30/4/68`}</li>
      <li parentName="ul">{`วันละ 2 ชั่วโมง วันจันทร์-ศุกร์ ช่วงปิดเทอม`}</li>
      <li parentName="ul">{`เวลาเรียน 10.00 โมง ถึง 12.00`}</li>
      <li parentName="ul">{`เรียนผ่านโปรแกรมซูม `}</li>
    </ul>
    <h3 {...{
      "id": "การสมัครเรียน",
      "style": {
        "position": "relative"
      }
    }}>{`การสมัครเรียน`}<a parentName="h3" {...{
        "href": "#%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99",
        "aria-label": "การสมัครเรียน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`โทร: 086-943-1115`}</li>
      <li parentName="ul">{`ติดต่อผ่าน Email: `}<a parentName="li" {...{
          "href": "mailto:anndream.com@gmail.com"
        }}>{`anndream.com@gmail.com`}</a></li>
      <li parentName="ul">{`จะได้รับการติดต่อกลับภายใน 24 ชั่วโมง เพื่อนัดการจ่ายเงินก่อนเริ่มนัดหมายการสอน`}</li>
    </ul>
    <h3 {...{
      "id": "โปรดระวังมิจฉาชีพทุกกรณีทางเราจะไม่มีการส่ง-link-ใดๆไปให้ลูกค้ากดเด็ดขาด",
      "style": {
        "position": "relative"
      }
    }}>{`โปรดระวังมิจฉาชีพทุกกรณีทางเราจะไม่มีการส่ง link ใดๆไปให้ลูกค้ากดเด็ดขาด`}<a parentName="h3" {...{
        "href": "#%E0%B9%82%E0%B8%9B%E0%B8%A3%E0%B8%94%E0%B8%A3%E0%B8%B0%E0%B8%A7%E0%B8%B1%E0%B8%87%E0%B8%A1%E0%B8%B4%E0%B8%88%E0%B8%89%E0%B8%B2%E0%B8%8A%E0%B8%B5%E0%B8%9E%E0%B8%97%E0%B8%B8%E0%B8%81%E0%B8%81%E0%B8%A3%E0%B8%93%E0%B8%B5%E0%B8%97%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%A3%E0%B8%B2%E0%B8%88%E0%B8%B0%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%A1%E0%B8%B5%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B9%88%E0%B8%87-link-%E0%B9%83%E0%B8%94%E0%B9%86%E0%B9%84%E0%B8%9B%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B8%A5%E0%B8%B9%E0%B8%81%E0%B8%84%E0%B9%89%E0%B8%B2%E0%B8%81%E0%B8%94%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94%E0%B8%82%E0%B8%B2%E0%B8%94",
        "aria-label": "โปรดระวังมิจฉาชีพทุกกรณีทางเราจะไม่มีการส่ง link ใดๆไปให้ลูกค้ากดเด็ดขาด permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h3 {...{
      "id": "ไม่มั่นใจให้ติดต่อผ่านเบอร์โทรศัพท์ก่อน",
      "style": {
        "position": "relative"
      }
    }}>{`ไม่มั่นใจให้ติดต่อผ่านเบอร์โทรศัพท์ก่อน`}<a parentName="h3" {...{
        "href": "#%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%A1%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%83%E0%B8%88%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%9C%E0%B9%88%E0%B8%B2%E0%B8%99%E0%B9%80%E0%B8%9A%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B9%82%E0%B8%97%E0%B8%A3%E0%B8%A8%E0%B8%B1%E0%B8%9E%E0%B8%97%E0%B9%8C%E0%B8%81%E0%B9%88%E0%B8%AD%E0%B8%99",
        "aria-label": "ไม่มั่นใจให้ติดต่อผ่านเบอร์โทรศัพท์ก่อน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h3 {...{
      "id": "เตรียมความพร้อมก่อนเรียน",
      "style": {
        "position": "relative"
      }
    }}>{`เตรียมความพร้อมก่อนเรียน`}<a parentName="h3" {...{
        "href": "#%E0%B9%80%E0%B8%95%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9E%E0%B8%A3%E0%B9%89%E0%B8%AD%E0%B8%A1%E0%B8%81%E0%B9%88%E0%B8%AD%E0%B8%99%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99",
        "aria-label": "เตรียมความพร้อมก่อนเรียน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`คอมพิวเตอร์หรือโน๊ตบุค`}</li>
      <li parentName="ul">{`เชื่อมต่อไวไฟ`}</li>
      <li parentName="ul">{`ห้องส่วนตัวหรือบริเวณที่ไม่เกิดเสียงดังรบกวนระหว่างเรียน`}</li>
      <li parentName="ul">{`โปรแกรมซูม`}</li>
      <li parentName="ul">{`สมุดดินสอ ปากกา`}</li>
      <li parentName="ul">{`ความตั้งใจ `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      